import React, { useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import SearchClose from "/static/images/search_close.svg"
import SearchSpanner from "/static/images/search_spanner.svg"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => {
    const [hasFocus, setFocus] = useState(false);
    const [inputText, setInputText] = useState('');

    const handleFocus = (props) => {
      setFocus(props)
    };

    let timerId = null;
    const delay = 1000;

    const [newRefinement, setNewRefinement] = useState(currentRefinement);

    const onChangeDebounced = (event) => {
      const value = event.currentTarget.value;

      clearTimeout(timerId);
      timerId = setTimeout(() => refine(value), delay);

      setNewRefinement(value);
      setInputText(value)
    };

    const handleReset = (props) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => refine(props), delay);
      setNewRefinement(props)
    };

    return (
      <form
        className={className}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 mt-1 flex items-center px-6">
          <SearchSpanner className={`duration-300 ${inputText ? 'stroke-darkgrey' : hasFocus ? 'stroke-darkgrey' : 'stroke-grey'}`}/>
        </div>
        <input
          className="mx-auto block w-full !border-0 bg-white lg:py-8 py-6 pr-12 pl-12 text-[16px] text-darkgrey focus:text-darkgrey focus:shadow-none focus:outline-none focus:ring-0 focus:ring-transparent md:text-[20px] placeholder:text-grey text-ellipsis"
          type="text"
          placeholder="What do you want to search for?"
          aria-label="Search"
          onChange={(e) => onChangeDebounced(e)}
          value={newRefinement}
          onFocus={()=>handleFocus(true)}
          onBlur={()=>handleFocus(false)}
        />
        <button
          className={`cursor-pointer absolute inset-y-0 right-0 flex items-center pl-2 lg:pr-10 pr-6 duration-300 ${inputText ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
          onClick={()=>{handleReset(''); setInputText('')}}
        >
          <SearchClose className={`duration-300 ${inputText ? 'text-darkgrey' : hasFocus ? 'text-darkgrey' : 'text-grey'}`}/>
        </button>
      </form>
    );
  }
);
