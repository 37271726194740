import TBBLink from "utils/TBBLink";
import React, { useEffect, useState } from "react";
import { connectHits, Index } from "react-instantsearch-dom";
import TBBImage from "src/utils/TBBImage";
import FormatDate from "src/utils/FormatDate";

const PageHit = ({ hit }) => {
  return (
    <TBBLink className="group relative flex flex-col justify-end" to={hit.full_slug}>
      <div className="relative w-full overflow-hidden">
        {hit.thumbnail_image && (
          <TBBImage
            className="duration-300"
            image={hit.thumbnail_image}
            alt={hit.thumbnail_image.alt}
          />
        )}
        <div className="z-10 duration-300 group-hover:opacity-100 opacity-0 absolute top-0 left-0 h-full w-full flex justify-center items-center text-4.5xl text-darkgrey">
          <div className="bg-beige absolute top-0 left-0 h-full w-full group-hover:opacity-70 opacity-0"></div>
          <span className="z-10">Read</span>
        </div>
      </div>
      <div className="mt-4 flex flex-col justify-start h-[122px]">
        <span className="mb-1 block text-[12px] uppercase tracking-[0.02em] text-darkgrey">
          {hit.tag_list.join(", ")}
        </span>
        <h4 className="text-sm md:text-base leading-none mb-0 text-darkgrey">
          {hit.name}
        </h4>
        <span className="font-label mt-3 text-[13px] text-grey">
          {FormatDate(hit.first_published_at)}
        </span>
      </div>
    </TBBLink>
  );
};

const Hits = ({ hits }) => {
  const [noResults, setNoResults] = useState();
  // Check if there are any search results
  useEffect(() => {
    if (hits.length < 1) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
  }, [hits]);

  return (
    <>
      <div className="mx-auto mt-6 grid grid-cols-1 gap-y-9 sm:grid-cols-2 lg:grid-cols-3 gap-x-[22px]">
        {hits.map((hit) => (
          <PageHit hit={hit} />
        ))}
      </div>
      {noResults && (
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-center gap-4 py-24 text-center">
          <svg
            className="h-8 w-8 stroke-yellow"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="search-icon"
          >
            <path
              d="M7.25 12.75C10.5637 12.75 13.25 10.0637 13.25 6.75C13.25 3.43629 10.5637 0.75 7.25 0.75C3.93629 0.75 1.25 3.43629 1.25 6.75C1.25 10.0637 3.93629 12.75 7.25 12.75Z"
              stroke="current"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.7508 14.25L11.4883 10.9875"
              stroke="current"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-[20px] font-semibold text-black">
            Sorry, we didn’t find any matches for your search.
          </p>
        </div>
      )}
    </>
  );
};

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <CustomHits />
  </Index>
);

const SearchResult = ({ indices, show }) => (
  <div>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
);

export default SearchResult;
