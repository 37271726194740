import React from "react";
import ClearFilters from "./ClearFilters"
import { connectCurrentRefinements } from "react-instantsearch-dom";
import TitleCase from "src/utils/TitleCase";

const CurrentRefinements = ({ items, refine, createURL }) => {
  return (
    items.length > 0 &&
        <div className="flex lg:flex-row flex-col flex-wrap justify-start lg:items-center lg:pb-6 gap-2">
            <div className="font-label lg:text-base text-sm text-grey">Selected:</div>
            {items.map((item) => (
                item.items ? (
                    <React.Fragment>
                        {item.items.map((nested) => (
                            <a
                                href={createURL(nested.value)}
                                onClick={(event) => {
                                    event.preventDefault();
                                    refine(nested.value);
                                }}
                                key={nested.label}
                                className="bg-beige text-darkgrey py-3 px-4 text-sm self-start uppercase duration-300"
                            >
                                {TitleCase(nested.label.replace(/-/g, ' ').replace(/_/g, ' '))}
                                <i className="far fa-times ml-3 mt-1" aria-hidden="true"></i>
                            </a>
                        ))}
                    </React.Fragment>
                ) : (
                    <a
                        href={createURL(item.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {item.label.replace(/-/g, ' ').replace(/_/g, ' ')}
                    </a>
                )
            ))}
            <ClearFilters className="lg:block hidden"/>
        </div>
  )
}

export default connectCurrentRefinements(CurrentRefinements);