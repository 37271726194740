import React, { useState, useEffect } from "react"
import ClearFilters from "./ClearFilters"
import HeaderArrow from '/static/images/header_arrow.svg'

const MobileFilters = ({ children }) => {

  const [open, setOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false);

  // Change the style of the navbar on scroll
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Set the body to overflow hidden when mobile filter is open
  useEffect(() =>{
    if( open ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
    window.addEventListener("scroll", handleScroll);
  }, [open, scrolled])

  return (
      <>
        {/* Filter Button */}
        <div 
          className={'block lg:hidden bg-white'}
          onClick={() => setOpen(true)}
          onKeyPress={() => setOpen(true)}
          role="button"
          tabIndex="0"
        >
          <div className="h-px w-10/12 mx-auto bg-[#EAEAEA]"></div>
          <div className="font-body uppercase text-[13px] bg-white text-black leading-none text-center w-full py-6">Filters +</div>
        </div>
        {/* Filter Popup */}
        <div className={`${open ? 'visible' : 'invisible'}`}>
            <div className={`fixed z-[100] inset-0 overflow-y-auto ${scrolled ? 'top-0' : 'top-0'} ${open ? 'opacity-100' : 'opacity-0'} duration-300 ease-in-out transition`}>
                <div className={`flex items-end justify-center text-center sm:block sm:p-0 ${scrolled ? 'h-[calc(100vh-0px)]' : 'h-[calc(100vh-0px)]'}`}>
                    <div className="flex flex-col justify-between min-h-full w-full align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all">
                      <div>
                        <div className="relative h-[98px] flex lg:flex-col flex-row justify-between lg:items-center items-end transition-all duration-300 lg:px-8 px-8 lg:py-8 py-6">
                            <button onClick={() => setOpen(false)} className={`lg:px-2 lg:py-0 py-2 lg:mb-0 -mb-2 lg:-order-none order-first lg:absolute bottom-8 left-1/2 lg:-translate-x-1/2 lg:rotate-180 flex flex-row justify-center items-center text-xs uppercase tracking-[1px] transition-colors duration-300 hover:text-beige lg:[writing-mode:vertical-lr]`}>
                              <HeaderArrow className="lg:rotate-180 rotate-90 lg:mb-2.5 lg:mr-0 mr-2.5"/>
                              Filters
                            </button>
                            <div className="h-full flex flex-col items-center justify-end outline-none focus:outline-none">
                              <button className={`group w-[26px] h-[26px] relative focus:outline-none outline-none`} tabIndex={0} onClick={() => setOpen(false)}>
                                <div className={`bg-darkgrey group-hover:bg-beige h-[3px] absolute left-0 duration-300 -translate-y-1/2 top-1/2 rotate-45 w-full`}></div>
                                <div className={`bg-darkgrey group-hover:bg-beige h-[3px] absolute left-0 duration-300 -translate-y-1/2 top-1/2 rotate-[135deg] w-full`}></div>
                              </button>
                            </div>
                        </div>
                        <div className="w-full px-6 pt-2">
                            <div className="flex flex-col gap-1">
                              {children}
                            </div>
                        </div>
                      </div>
                      <div className="px-6 py-6 flex flex-row gap-2">
                        <ClearFilters className="w-1/2"/>
                        <button
                            type="button"
                            className="block uppercase px-5 py-4 text-sm tracking-[1.2px] text-white bg-black w-1/2"
                            onClick={() => setOpen(false)}
                        >
                            See results
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default MobileFilters