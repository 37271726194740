import React from "react";
import Search from "src/components/RA/Search";
import Layout from "src/components/Layout";
import Seo from "src/utils/seo";
import { PageContext } from "../utils/context";

const SearchPage = ({ location }) => {
  const searchIndices = [{ name: `Articles`, title: `Articles` }];
  return (
    <PageContext.Provider
        value={{
        breadcrumb: '',
        location: location,
        }}
    >
        <Layout location={location}>
            <Seo pageName={'Knowledge Hub'}/>
            <Search indices={searchIndices} location={location}/>
        </Layout>
    </PageContext.Provider>
  );
};

export default SearchPage;