import {
  createRef,
  default as React,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch-dom";
import SearchBox from "./SearchBox";
import SearchResult from "./SearchResult";
import CurrentFilters from "./CurrentFilters";
import ContentTypeFilter from "./ContentTypeFilter";
import Pagination from "./Pagination";
import persistantOrder from "./persistantOrder";
import useClickOutside from "./useClickOutside";
import MobileFilters from "./MobileFilters";
import qs from "qs";
import { navigate } from "gatsby";
import { BrowserView, MobileView } from "react-device-detect";

const Search = ({ indices, location }) => {
  const createURL = (state) => `/knowledge-hub/?${qs.stringify(state)}`;

  const searchStateToUrl = (searchState) =>
    searchState ? createURL(searchState) : "";

  const urlToSearchState = ({ search }) => {
    return qs.parse(search.slice(1));
  };

  const rootRef = createRef();
  const [hasFocus, setFocus] = useState(false);
  const DEBOUNCE_TIME = 400;
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );
  useClickOutside(rootRef, () => setFocus(false));

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <div className="mx-auto max-w-7xl px-6 lg:py-40 py-20">
        <div className="relative w-full">
          <h1 className="font-body text-4xl lg:text-6xl leading-none text-darkgrey mb-8">Knowledge Hub</h1>
          <div className="mx-auto flex-grow shadow-sm">
            <SearchBox
              onFocus={() => setFocus(true)}
              hasFocus={hasFocus}
              className="relative"
            />
            {
              <MobileView>
                <MobileFilters>
                  <ContentTypeFilter
                    attribute="tag_list"
                    limit={100}
                    transformItems={(items) => persistantOrder(items)}
                  />
                  <CurrentFilters/>
                </MobileFilters>           
              </MobileView>              
            }
          </div>
        </div>
        <div className="flex flex-col py-6">
          <div className="hidden lg:block">
            {
              <BrowserView>
                <ContentTypeFilter
                  attribute="tag_list"
                  limit={100}
                  transformItems={(items) => persistantOrder(items)}
                />
                <CurrentFilters/>
              </BrowserView>              
            }
          </div>
          <div className="w-full">
            <SearchResult
              show={searchState && searchState.length > 0 && hasFocus}
              indices={indices}
            />
          </div>
        </div>
        <div>
          <Pagination />
        </div>
      </div>
    </InstantSearch>
  );
};

export default Search;
