import React from "react"
import { connectRefinementList } from 'react-instantsearch-dom';
import TitleCase from "src/utils/TitleCase";

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
}) => (
  <div className="flex lg:flex-row flex-col py-5 border-b border-[#EAEAEA] mb-7">
    {items.map(item => (
      <a
        href={createURL(item.value)}
        onClick={event => {
          event.preventDefault();
          refine(item.value);
        }}
        key={item.label}
        className="lg:mx-3 first:ml-0 last:mr-0"
      >
          <div className="flex flex-row items-center group py-1">
            <span className={`lg:hidden block h-4 w-4 rounded-full border mr-2 duration-300 ${item.isRefined ? "bg-[#2F3031] border-[#2F3031]" : "bg-white border-[#E0E0E0]"}`}></span>
            <span className={`text-[14px] cursor-pointer uppercase font-[500] lg:group-hover:text-grey duration-300 ${item.isRefined ? "text-grey" : "text-darkgrey"}`}>
              {TitleCase(item.label.replace(/-/g, ' ').replace(/_/g, ' '))}
            </span>
          </div>          
      </a>
    ))}
  </div>
);

export default connectRefinementList(RefinementList);